<template>
  <div v-if="!item.hidden">
    <template
      v-if="hasOneShowingChild(item.children, item) && (!onlyOneChild.children || onlyOneChild.noShowingChildren) && !item.alwaysShow">
      <app-link v-if="onlyOneChild.meta" :to="resolvePath(onlyOneChild.path)">
        <el-menu-item :index="resolvePath(onlyOneChild.path)" :class="{ 'submenu-title-noDropdown': !isNest }">
          <item :icon="onlyOneChild.meta.icon || (item.meta && item.meta.icon)" :title="onlyOneChild.meta.title" />
        </el-menu-item>
      </app-link>
    </template>

    <el-submenu :popper-class="popperClass || 'top-nav-submenu-wrap'" v-else :index="resolvePath(item.path)"
      :show-timeout="0" :hide-timeout="0" popper-append-to-body>
      <template slot="title">
        <item v-if="item.meta" :icon="item.meta && item.meta.icon" :title="item.meta.title" />
      </template>
      <top-nav-item v-for="child in item.children" :key="child.path" :is-nest="true" :item="child"
        :base-path="resolvePath(child.path)" class="top-nav-menu-item" popperClass="top-nav-nextmenu-wrap" />
    </el-submenu>
  </div>
</template>

<script>
import path from 'path'
import { isExternal } from '@/utils/validate'
import { isEmpty } from "@/utils/janezt";
import AppLink from '../Sidebar/Link'
import FixiOSBug from '../Sidebar/FixiOSBug'
import Item from '../Sidebar/Item'
export default {
  name: 'TopNavItem',
  components: { AppLink, Item },
  mixins: [FixiOSBug],
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    },
    popperClass: {
      type: String,
      default: ""
    }
  },
  data() {
    this.onlyOneChild = null
    return {}
  },
  computed: {
    theme() {
      return this.$store.state.settings.theme;
    },
  },
  methods: {
    isEmpty,
    hasOneShowingChild(children = [], parent) {
      const showingChildren = children.filter(item => {
        if (item.hidden) {
          return false
        } else {
          // Temp set(will be used if only has one showing child)
          this.onlyOneChild = item
          return true
        }
      })

      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true
      }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = { ...parent, path: '', noShowingChildren: true }
        return true
      }

      return false
    },
    resolvePath(routePath) {
      if (isExternal(routePath)) {
        return routePath
      }
      if (isExternal(this.basePath)) {
        return this.basePath
      }
      return path.resolve(this.basePath, routePath)
    }
  }
}
</script>
<style lang="scss">
.el-menu--horizontal .el-menu .el-menu-item,
.el-menu--horizontal .el-menu .el-submenu__title {
  color: #3E3F3F;
}

// 二级以下菜单项的选中和鼠标移入样式
.top-nav-menu-item {

  .el-menu-item,
  .el-submenu {

    &.is-active,
    &:hover {
      color: var(--current-color) !important;
      background-color: #E7F2FE;

      >.el-submenu__title {
        color: var(--current-color) !important;
        background-color: #E7F2FE;

        >* {
          color: var(--current-color) !important;
        }
      }
    }
  }
}

//二级菜单
.top-nav-submenu-wrap {
  z-index: 2100 !important;
}

//三级菜单的样式
.top-nav-nextmenu-wrap {
  z-index: 2010 !important;

  &.el-menu--horizontal {
    background-color: #FFFFFF;

    .el-menu {
      box-shadow: 0px 9px 28px 8px rgba(49, 50, 51, 0.05), 0px 6px 16px 0px rgba(49, 50, 51, 0.08), 0px 3px 6px -4px rgba(49, 50, 51, 0.12);


      .el-menu-item {
        background-color: inherit;
      }
    }

  }
}
</style>

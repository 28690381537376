import hasRole from './permission/hasRole'
import hasPermi from './permission/hasPermi'
import dialogDrag from './dialog/drag'
import copy from './copy'
import { spacelimit } from './input'

const install = function (Vue) {
  Vue.directive('hasRole', hasRole)
  Vue.directive('hasPermi', hasPermi)
  Vue.directive('dialogDrag', dialogDrag)
  Vue.directive('copy', copy)
  Vue.directive('spacelimit', spacelimit)
}

if (window.Vue) {
  window['hasRole'] = hasRole
  window['hasPermi'] = hasPermi
  window['copy'] = copy
  Vue.use(install); // eslint-disable-line
}

export default install

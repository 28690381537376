import request from '@/utils/request'

// 获取路由
export const getRouters = (query) => {
  return request({
    url: '/master/menu/getRouters',
    method: 'get',
    params: query
  })
}

// 获取子系统列表
export const getSystemList = (query) => {
  return request({
    url: '/master/menu/systemListShowAll',
    method: 'get',
    params: query

  })
}

// 保存用户菜单个性化配置
export const saveSysUserMenuConfig = (data) => {
  return request({
    url: '/master/menu/saveSysUserMenuConfig',
    method: 'post',
    data: data
  })
}

<template>
  <div class="sidebar" :class="{ 'has-logo': showLogo }"
    :style="{ backgroundColor: settings.sideTheme === 'theme-dark' ? variables.menuBg : variables.menuLightBg }">
    <logo v-if="showLogo" :collapse="isCollapse" />
    <el-scrollbar :class="settings.sideTheme" wrap-class="scrollbar-wrapper">
      <el-menu :default-active="activeMenu" :collapse="isCollapse"
        :background-color="settings.sideTheme === 'theme-dark' ? variables.menuBg : variables.menuLightBg"
        :text-color="settings.sideTheme === 'theme-dark' ? variables.menuText : 'rgba(0,0,0,.65)'" :unique-opened="false"
        :collapse-transition="false" class="sidebar-menu" mode="vertical">
        <sidebar-item v-for="(route, index) in sidebarRouters" :key="route.path + index" :item="route"
          :base-path="route.path" />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Logo from "./Logo";
import SidebarItem from "./SidebarItem";
import variables from "@/assets/styles/variables.scss";

export default {
  components: { SidebarItem, Logo },
  computed: {
    ...mapState(["settings"]),
    ...mapGetters(["sidebarRouters", "sidebar"]),
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables() {
      return variables;
    },
    isCollapse() {
      return !this.sidebar.opened;
    }
  }
};
</script>
<style lang="scss" scoped>
.sidebar {
  .sidebar-logo-container {
    height: 119px;
  }
}

::v-deep.sidebar-menu {
  .el-menu-item.is-active {
    background: linear-gradient(90deg, #e9f1ff, #efe9fb) !important;
    position: relative;

    &::before {
      position: absolute;
      width: 48px;
      height: 100%;
      top: 0;
      left: 0;
      content: "";
      display: block;
      // background: #5169fe;
      border-radius: 0px 24px 24px 0px;
      border-left: solid 4px var(--current-color);
      ;
      z-index: 0;
    }

    >.svg-icon {
      color: var(--current-color);
    }
  }

  .nest-menu {
    .el-menu-item.is-active::before {
      width: 65px;
    }
  }
}

::v-deep.el-menu--collapse {
  .el-menu-item.is-active {
    &::before {
      width: 75px;
    }

    .svg-icon {
      color: #5169fe;
    }
  }

  .el-submenu__icon-arrow {
    display: none;
  }
}
</style>

<template>
  <div class="sidebar-logo-container" :class="{ 'collapse': collapse, 'theme-light': sideTheme === 'theme-light' }">
    <transition name="sidebarLogoFade">
      <router-link v-if="collapse" key="collapse" class="sidebar-logo-link" to="/">
        <img v-if="logo" :src="logo" class="sidebar-logo" />
        <h1 v-else class="sidebar-title">{{ title }} </h1>
      </router-link>
      <router-link v-else key="expand" class="sidebar-logo-link" to="/">
        <img v-if="logo" :src="logo" class="sidebar-logo" />
        <h1 class="sidebar-title">{{ title }} </h1>
      </router-link>
    </transition>
  </div>
</template>

<script>
import logoImg from '@/assets/logo/logo.png'
import variables from '@/assets/styles/variables.scss'

export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    variables() {
      return variables;
    },
    sideTheme() {
      return this.$store.state.settings.sideTheme
    }
  },
  data() {
    return {
      title: '品牌驾驶舱',
      logo: logoImg
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  display: flex;
  justify-content: center;
  align-content: center;
  position: relative;
  background: inherit;
  text-align: center;
  overflow: hidden;
  padding: 10px;

  & .sidebar-logo-link {
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 100%;

    & .sidebar-logo {
      width: 32px;
      height: 32px;
      vertical-align: middle;
      margin-right: 12px;
    }

    & .sidebar-title {
      display: inline-block;
      margin: 0;
      color: #fff;
      font-weight: 600;
      line-height: 38px;
      font-size: 16px;
      font-family: 阿里巴巴普惠体-B, PingFangSC, PingFangSC-Semibold, Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
      font-weight: 600;
      text-align: right;
    }
  }

  &.theme-light {
    .sidebar-title {
      color: rgba($color: #000000, $alpha: 0.8);
    }
  }

  &.collapse {
    .sidebar-logo {
      margin-right: 0px;
    }
  }
}
</style>

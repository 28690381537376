var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "navbar" }, [
    _c(
      "div",
      { staticClass: "left-memnu" },
      [
        _vm.topNav ? _c("logo") : _vm._e(),
        !_vm.topNav
          ? _c("hamburger", {
              staticClass: "hamburger-container",
              attrs: {
                id: "hamburger-container",
                "is-active": _vm.sidebar.opened,
              },
              on: { toggleClick: _vm.toggleSideBar },
            })
          : _vm._e(),
        !_vm.topNav
          ? _c("breadcrumb", {
              staticClass: "breadcrumb-container",
              attrs: { id: "breadcrumb-container" },
            })
          : _vm._e(),
        _vm.topNav
          ? _c("top-nav", {
              staticClass: "topmenu-container",
              attrs: { id: "topmenu-container" },
            })
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "right-menu" },
      [
        _vm.device !== "mobile"
          ? [
              _c("search", {
                staticClass: "right-menu-item",
                attrs: { id: "header-search" },
              }),
              _c("screenfull", {
                staticClass: "right-menu-item hover-effect",
                attrs: { id: "screenfull" },
              }),
              _c(
                "el-tooltip",
                {
                  attrs: {
                    content: "布局大小",
                    effect: "dark",
                    placement: "bottom",
                  },
                },
                [
                  _c("size-select", {
                    staticClass: "right-menu-item hover-effect",
                    attrs: { id: "size-select" },
                  }),
                ],
                1
              ),
              _c("span", { staticClass: "line-span" }),
            ]
          : _vm._e(),
        _c(
          "el-dropdown",
          {
            staticClass: "avatar-container right-menu-item hover-effect",
            attrs: { trigger: "click" },
          },
          [
            _c("div", { staticClass: "avatar-wrapper" }, [
              _c("img", {
                staticClass: "user-avatar",
                attrs: { src: _vm.avatar },
              }),
              _c("i", { staticClass: "el-icon-caret-bottom" }),
            ]),
            _c(
              "el-dropdown-menu",
              { attrs: { slot: "dropdown" }, slot: "dropdown" },
              [
                _c(
                  "router-link",
                  { attrs: { to: "/user/profile" } },
                  [_c("el-dropdown-item", [_vm._v("个人中心")])],
                  1
                ),
                _c(
                  "el-dropdown-item",
                  {
                    nativeOn: {
                      click: function ($event) {
                        _vm.setting = true
                      },
                    },
                  },
                  [_c("span", [_vm._v("布局设置")])]
                ),
                _c(
                  "el-dropdown-item",
                  {
                    attrs: { divided: "" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.logout($event)
                      },
                    },
                  },
                  [_c("span", [_vm._v("退出登录")])]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <el-menu class="top-nav" :default-active="activeMenu" mode="horizontal" :style="{ '--theme': theme }"
    @select="handleSelect">
    <top-nav-item v-for="(route, index) in topMenus" v-if="index < visibleNumber" :key="index" :item="route"
      :base-path="route.path" />

    <!-- 顶部菜单超出数量折叠 -->
    <el-submenu index="more" v-if="topMenus.length > visibleNumber" :show-timeout="0" :hide-timeout="0">
      <template slot="title">更多菜单</template>
      <template v-for="(route, index) in topMenus.slice(visibleNumber)">
        <top-nav-item :item="route" :base-path="route.path" />
      </template>
    </el-submenu>
  </el-menu>
</template>

<script>
import { constantRoutes } from "@/router";
import TopNavItem from './item.vue'

export default {
  components: {
    TopNavItem
  },
  data() {
    return {
      // 顶部栏初始数
      visibleNumber: 5,
      // 是否为首次加载
      isFrist: false,
      // 当前激活菜单的 index
      currentIndex: undefined
    };
  },
  computed: {
    theme() {
      return this.$store.state.settings.theme;
    },
    // 顶部显示菜单
    topMenus() {
      let topMenus = [];
      this.routers.map((menu) => {
        if (menu.hidden !== true) {
          // 兼容顶部栏一级菜单内部跳转
          if (menu.path === "/") {
            topMenus.push(menu.children[0]);
          } else {
            topMenus.push(menu);
          }
        }
      });
      return topMenus;
    },
    // 所有的路由信息
    routers() {
      return this.$store.state.permission.topbarRouters;
    },
    // 设置子路由
    childrenMenus() {
      var childrenMenus = [];
      this.routers.map((router) => {
        for (var item in router.children) {
          if (router.children[item].parentPath === undefined) {
            if (router.path === "/") {
              router.children[item].path = "/redirect/" + router.children[item].path;
            } else {
              if (!this.ishttp(router.children[item].path)) {
                router.children[item].path = router.path + "/" + router.children[item].path;
              }
            }
            router.children[item].parentPath = router.path;
          }
          childrenMenus.push(router.children[item]);
        }
      });
      return constantRoutes.concat(childrenMenus);
    },
    // 默认激活的菜单
    activeMenu() {
      const path = this.$route.path;
      let activePath = this.routers[0].path;
      if (path.lastIndexOf("/") > 0) {
        const tmpPath = path.substring(1, path.length);
        activePath = "/" + tmpPath.substring(0, tmpPath.indexOf("/"));
      } else if ("/index" == path || "" == path) {
        if (!this.isFrist) {
          this.isFrist = true;
        } else {
          activePath = "index";
        }
      }
      var routes = this.activeRoutes(activePath);
      if (routes.length === 0) {
        activePath = this.currentIndex || this.routers[0].path
        this.activeRoutes(activePath);
      }
      return path;
    },
  },
  beforeMount() {
    window.addEventListener('resize', this.setVisibleNumber)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setVisibleNumber)
  },
  mounted() {
    this.setVisibleNumber();
  },
  methods: {
    // 根据宽度计算设置显示栏数
    setVisibleNumber() {
      const width = document.body.getBoundingClientRect().width / 3;
      this.visibleNumber = parseInt(width / 85);
    },
    // 菜单选择事件
    handleSelect(key, keyPath) {
      this.currentIndex = key;
      if (this.ishttp(key)) {
        // http(s):// 路径新窗口打开
        window.open(key, "_blank");
      } else if (key.indexOf("/redirect") !== -1) {
        // /redirect 路径内部打开
        this.$router.push({ path: key.replace("/redirect", "") });
      } else {
        // 显示左侧联动菜单
        this.activeRoutes(key);
      }
    },
    // 当前激活的路由
    activeRoutes(key) {
      var routes = [];
      if (this.childrenMenus && this.childrenMenus.length > 0) {
        this.childrenMenus.map((item) => {
          if (key == item.parentPath || (key == "index" && "" == item.path)) {
            routes.push(item);
          }
        });
      }
      if (routes.length > 0) {
        this.$store.commit("SET_SIDEBAR_ROUTERS", routes);
      }
      return routes;
    },
    ishttp(url) {
      return url.indexOf('http://') !== -1 || url.indexOf('https://') !== -1
    }
  },
};
</script>

<style lang="scss" scoped>
.top-nav {
  background-color: inherit;
}

.el-menu.el-menu--horizontal {
  border-bottom: none !important;
}

.el-menu--horizontal>.el-menu-item {
  float: left;
  height: 50px;
  line-height: 50px;
  margin: 0;
  border-bottom: 3px solid transparent;
  padding: 0 5px;
  margin: 0 10px;
}

.el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: 3px solid #{'var(--theme)'};
  color: var(--theme);
}

/* submenu item */
.el-menu--horizontal>.el-submenu .el-submenu__title {
  height: 50px !important;
  line-height: 50px !important;
}

::v-deep.top-nav.el-menu--horizontal {
  // .el-menu-item,
  // .el-submenu {
  //   &.is-active {
  //     border-bottom: 3px solid var(--theme);
  //     color: var(--theme);
  //   }
  // }

  .el-menu-item.is-active,
  .el-submenu.is-active .el-submenu__title {
    color: var(--theme);

    &::after {
      margin-top: -5px;
      display: block;
      content: "";
      border-bottom: 3px solid var(--theme);
      color: var(--theme);
    }
  }
}
</style>
<style lang="scss">
.el-menu-item>svg.svg-icon {
  margin-right: 5px;
}

.el-menu--popup-bottom-start,
.el-menu--popup-right-start {
  margin: 0;
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-menu",
    {
      staticClass: "top-nav",
      style: { "--theme": _vm.theme },
      attrs: { "default-active": _vm.activeMenu, mode: "horizontal" },
      on: { select: _vm.handleSelect },
    },
    [
      _vm._l(_vm.topMenus, function (route, index) {
        return index < _vm.visibleNumber
          ? _c("top-nav-item", {
              key: index,
              attrs: { item: route, "base-path": route.path },
            })
          : _vm._e()
      }),
      _vm.topMenus.length > _vm.visibleNumber
        ? _c(
            "el-submenu",
            { attrs: { index: "more", "show-timeout": 0, "hide-timeout": 0 } },
            [
              _c("template", { slot: "title" }, [_vm._v("更多菜单")]),
              _vm._l(
                _vm.topMenus.slice(_vm.visibleNumber),
                function (route, index) {
                  return [
                    _c("top-nav-item", {
                      attrs: { item: route, "base-path": route.path },
                    }),
                  ]
                }
              ),
            ],
            2
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
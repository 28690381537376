const spacelimit  = {
  bind: function (el, { value, modifiers }, vnode) {
    let len = value || 500
    let inputEl
    if (el.tagName.toLowerCase() !== 'input') {
      inputEl = el.children[0]
    } else {
      inputEl = el
    }
    // 派发input事件
    inputEl.addEventListener('input', event => {
      let val = inputEl.value.trim()
      val = val?.split(' ')
      if (val?.length > len) {
        inputEl.value = val.splice(0, len).join(' ')
        inputEl.dispatchEvent(new Event('input'))
      }
    })
  }
}
export { spacelimit }

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar",
      class: { "has-logo": _vm.showLogo },
      style: {
        backgroundColor:
          _vm.settings.sideTheme === "theme-dark"
            ? _vm.variables.menuBg
            : _vm.variables.menuLightBg,
      },
    },
    [
      _vm.showLogo
        ? _c("logo", { attrs: { collapse: _vm.isCollapse } })
        : _vm._e(),
      _c(
        "el-scrollbar",
        {
          class: _vm.settings.sideTheme,
          attrs: { "wrap-class": "scrollbar-wrapper" },
        },
        [
          _c(
            "el-menu",
            {
              staticClass: "sidebar-menu",
              attrs: {
                "default-active": _vm.activeMenu,
                collapse: _vm.isCollapse,
                "background-color":
                  _vm.settings.sideTheme === "theme-dark"
                    ? _vm.variables.menuBg
                    : _vm.variables.menuLightBg,
                "text-color":
                  _vm.settings.sideTheme === "theme-dark"
                    ? _vm.variables.menuText
                    : "rgba(0,0,0,.65)",
                "unique-opened": false,
                "collapse-transition": false,
                mode: "vertical",
              },
            },
            _vm._l(_vm.sidebarRouters, function (route, index) {
              return _c("sidebar-item", {
                key: route.path + index,
                attrs: { item: route, "base-path": route.path },
              })
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }